import React, { useEffect, useRef } from "react";
import Prism from "prismjs";
import "./style/prism.css";

export default function Code({ node }) {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      Prism.highlightAllUnder(ref.current);
    }
  });

  return (
    <pre ref={ref} className={`language-${node.language}`}>
      <code>{node.code}</code>
    </pre>
  );
}
