import Code from "./Code";
import YouTube from "./YouTube";
import slugify from "slugify";
import React from "react";
import BlockContent from "@sanity/block-content-to-react";

const BlockRenderer = (props) => {
  const { style = "normal" } = props.node;

  if (/^h\d/.test(style)) {
    const id = slugify(props.children.join("").toLowerCase());
    return React.createElement(style, { id }, props.children);
  }

  return BlockContent.defaultSerializers.types.block(props);
};

const serializers = {
  types: {
    block: BlockRenderer,
    code: Code,
    youtube: YouTube,
  },
};

export default serializers;
