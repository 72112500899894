import React from "react";
import Container from "../ui/Container";
import BlockContent from "@sanity/block-content-to-react";
import { Link } from "gatsby";
import Banner from "../ui/Banner";
import { format } from "date-fns";
import serializers from "../../sanity/serializers";
import "./style/_post.css";
import SubscriptionForm from "../newsletter/SubscriptionForm";
import DefaultLayout from "../ui/DefaultLayout";
import { Helmet } from "react-helmet";
import excerpt from "./excerpt";

export default function PostPage({ pageContext }) {
  const { post } = pageContext;

  return (
    <DefaultLayout>
      <Helmet>
        <title>{post.title}</title>
        <meta name="description" content={excerpt(post)} />
      </Helmet>

      <header>
        <Banner url={post.mainImage.asset.url} />
        <div className="container pt-8 text-center">
          <h1 className="text-gray-900 text-4xl mb-3">{post.title}</h1>
          <p className="text-gray-600">
            Published {format(new Date(post.publishedAt), "PPP")} by{" "}
            <Link to="/" className="text-brand-blue">
              Emric Månsson
            </Link>
          </p>
        </div>
      </header>

      <Container>
        <div className="space-y-24 pb-16">
          <article className="post">
            <BlockContent
              projectId="svs9bwgm"
              dataset="production"
              blocks={post._rawBody}
              serializers={serializers}
            />
          </article>

          <SubscriptionForm />
        </div>
      </Container>
    </DefaultLayout>
  );
}
