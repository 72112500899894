import React from "react";

export default function YouTube({ node }) {
  return (
    <div
      style={{ paddingBottom: `${(9 / 16) * 100}%` }}
      className="relative mb-5 rounded-md overflow-x-scroll"
    >
      <iframe
        className="w-full absolute inset-0 h-full"
        title={`Youtube-${node.url}`}
        src={node.url}
      ></iframe>
    </div>
  );
}
