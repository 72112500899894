import React, { useState } from "react";
import authorEmric from "../../assets/author-emric.jpg";

const FormState = {
  Submitting: "SUBMITTING",
  Idle: "IDLE",
  InvalidForm: "INVALID_FORM",
  Error: "ERROR",
  Done: "DONE",
};

const displayWhen = (currentState, onStates) => {
  const baseClass = "transform transition-all absolute";

  if (Array(onStates).flat().includes(currentState)) {
    return `${baseClass} opacity-100 translate-y-0`;
  }

  return `${baseClass} opacity-0 translate-y-3 pointer-events-none`;
};

const submitForm = async (formValue) => {
  const url =
    "https://europe-west2-molten-method-344515.cloudfunctions.net/collectEmail";
  return fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(formValue),
  });
};

export default function SubscriptionForm() {
  const [state, setState] = useState(FormState.Idle);
  const [isValid, setIsValid] = useState(false);

  return (
    <>
      <section className="flex flex-col space-y-8 md:space-y-0 md:flex-row md:items-center md:space-x-10 rounded-md py-16">
        <img
          src={authorEmric}
          className="rounded-full border-4 h-48 w-48"
          alt="Emric smiling with arms crossed"
        />

        <div>
          <h2 className="text-gray-900 text-3xl mb-3">May I write to you?</h2>
          <p className="text-gray-800 mb-5 leading-relaxed">
            If you like what you see here, feel free to sign up to my
            newsletter! I’ll occasionally write to you about all things me, such
            as new blog posts, exciting projects or tips and tricks related to
            programming. You can unsubscribe at any time, no hard feelings!
          </p>

          <form
            className={`${displayWhen(state, [
              FormState.Idle,
              FormState.InvalidForm,
            ])} transition-all relative space-y-2`}
            onSubmit={async (ev) => {
              ev.preventDefault();

              if (isValid) {
                setState(FormState.Submitting);
                return submitForm(Object.fromEntries(new FormData(ev.target)))
                  .then((response) => {
                    if (response.ok) {
                      return setState(FormState.Done);
                    }

                    return setState(FormState.Error);
                  })
                  .catch(() => setState(FormState.Error));
              }

              setState(FormState.InvalidForm);
            }}
          >
            <div className="flex flex-row justify-between w-full">
              <input
                className="border rounded-l-md flex-1 px-3 flex-1 min-w-0"
                placeholder="user@example.com"
                type="email"
                name="email"
                onChange={(ev) => setIsValid(ev.target.validity.valid)}
                id="email"
              />
              <input
                className="rounded-r-md px-3 py-2
              transition-all duration-150 ease-out
              border border-brand-blue text-brand-blue
              hover:bg-brand-blue
              hover:text-white bg-white cursor-pointer
              "
                type="submit"
                value="Subscribe"
              />
            </div>
            <p
              className={`${displayWhen(
                state,
                FormState.InvalidForm
              )}} text-sm`}
            >
              Please enter a valid email.
            </p>
          </form>
          <p
            className={`${displayWhen(state, FormState.Submitting)}
            py-2 border border-transparent`}
          >
            Saving...
          </p>
          <p
            className={`${displayWhen(state, FormState.Done)}
            py-2 border border-transparent`}
          >
            Thank you for subscribing! :)
          </p>
          <p
            className={`${displayWhen(state, FormState.Error)}
            py-2 border border-transparent`}
          >
            Something wen't wrong. :(
          </p>
        </div>
      </section>
    </>
  );
}
